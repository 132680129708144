import { captureBreadcrumb } from '../../capture';
import { Provider } from './types';

export default {
  id: 'sentry',
  emit: (event) => {
    captureBreadcrumb({
      message: [event.flow, event.object, event.name, event.action]
        .filter(Boolean)
        .join(' '),
      level: 'info',
    });
  },
} as Provider;
