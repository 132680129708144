import { isBrowser } from '@utils/isBrowser';
import { useCallback, useEffect } from 'react';

import {
  UTM_CAMPAIGN_KEY,
  UTM_CONTENT_KEY,
  UTM_MEDIUM_KEY,
  UTM_SOURCE_KEY,
  UTM_TERM_KEY,
} from '../constants';
import { gtmEvent } from '../scripts/GoogleTagManager';
import { useSessionStorage } from './useSessionStorage';

export const useUTMTags = () => {
  const { setSessionStorage } = useSessionStorage();

  const setUTMKey = useCallback(
    (key: string, utmparams: URLSearchParams) => {
      const value = utmparams.get(key);
      if (value) {
        setSessionStorage(key, value);
      }
    },
    [setSessionStorage],
  );

  useEffect(() => {
    if (isBrowser()) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);

      setUTMKey(UTM_CAMPAIGN_KEY, urlParams);
      setUTMKey(UTM_MEDIUM_KEY, urlParams);
      setUTMKey(UTM_SOURCE_KEY, urlParams);
      setUTMKey(UTM_TERM_KEY, urlParams);
      setUTMKey(UTM_CONTENT_KEY, urlParams);

      gtmEvent('config', {
        utm_campaign: urlParams.get(UTM_CAMPAIGN_KEY),
        utm_content: urlParams.get(UTM_CONTENT_KEY),
        utm_medium: urlParams.get(UTM_MEDIUM_KEY),
        utm_source: urlParams.get(UTM_SOURCE_KEY),
        utm_term: urlParams.get(UTM_TERM_KEY),
        session_utm_campaign: urlParams.get(UTM_CAMPAIGN_KEY),
        session_utm_content: urlParams.get(UTM_CONTENT_KEY),
        session_utm_medium: urlParams.get(UTM_MEDIUM_KEY),
        session_utm_source: urlParams.get(UTM_SOURCE_KEY),
        session_utm_term: urlParams.get(UTM_TERM_KEY),
      });
    }
  }, [setUTMKey]);

  return;
};
