export const MAX_GRID_WIDTH = 1536;
export const FIVE_MINUTES_IN_SECONDS = 300;
export const FIFTEEN_MINUTES_IN_SECONDS = 900;
export const ONE_HOUR_IN_SECONDS = 3600;
export const THREE_MINUTES_IN_SECONDS = 180;
export const MAX_LIGHTBOX_IMAGE_WIDTH = 1536;
export const RECOMMENDATIONS_CACHE_KEY = 'recommendations_key';

export const HOME_PAGE_TYPE = 'home';
export const ARTIST_PAGE_TYPE = 'artist';
export const ARTIST_BIO_PAGE_TYPE = 'artist bio';
export const ARTIST_INDEX_PAGE_TYPE = 'artists index';
export const WORK_PAGE_TYPE = 'product';
export const WORKS_INDEX_PAGE_TYPE = 'works index';
export const SUPPORT_PAGE_TYPE = 'support';
export const SERIES_PAGE_TYPE = 'series';
export const JOURNAL_PAGE_TYPE = 'journal';
export const INSIGHTS_INDEX_PAGE_TYPE = 'insights index';
export const JOURNALS_FRANCHISE_INDEX_PAGE_TYPE = 'journals franchise index';
export const ERROR_PAGE_TYPE = '404';
export const ABOUT_PAGE_TYPE = 'about';
export const CAREERS_PAGE_TYPE = 'careers';
export const CHECKOUT_RECOVERY_PAGE_TYPE = 'checkout recovery';
export const GIFTCARD_PAGE_TYPE = 'gift card';
export const SIGININ_PAGE_TYPE = 'sign in';
export const SIGNUP_PAGE_TYPE = 'sign up';
export const ARTIST_SIGNUP_PAGE_TYPE = 'artist sign up';
export const VALIDATE_CODE_TYPE = 'validiate code';
export const SIGNUP_CHECKLIST_TYPE = 'signup check list';
export const ACCOUNT_ADDRESSES = 'account addresses';
export const ACCOUNT_ORDERS = 'account order history';
export const ACCOUNT_DETAILS = 'account personal details';
export const ACCOUNT_DRAW_ENTRIES = 'account draw entries';
export const LIVE_EVENT_PAGE_TYPE = 'live event';
export const REVIEWS_ORDER_PAGE_TYPE = 'reviews order';
export const GLOSSARY_INDEX = 'glossary index';
export const GLOSSARY_TERM_PAGE = 'glossary term page';
export const RELEASE_PAGE_TYPE = 'release page';

export const NFT_PRODUCT_PAGE_TYPE = 'nft work';
export const NFT_ASSET_PAGE_TYPE = 'nft asset';
export const NFT_PRINT_AUTH_PAGE_TYPE = 'nft print authentication';
export const NFT_PROJECT_PAGE_TYPE = 'nft project';

export const UTM_ORIGIN = 'marketplace';
export const UTM_ORIGIN_ARTE_BIO = 'artebio';
export const UTM_CAMPAIGN_KEY = 'utm_campaign';
export const UTM_MEDIUM_KEY = 'utm_medium';
export const UTM_CONTENT_KEY = 'utm_content';
export const UTM_SOURCE_KEY = 'utm_source';
export const UTM_TERM_KEY = 'utm_term';

export const HEADER_TITLE = 'Avant Arte';

export const ARTISTS_PER_PAGE = 24;
export const WORKS_PER_PAGE = 32;

export const PLACE_HOLDER_IMAGE = '/images/placeholder.png';
export const UNFRAMED_IMAGE = '/images/unframed.jpg';
export const NFT_PAGE_ROOT_PATH = 'nft';
export const PRODUCTS_PAGE_ROOT_PATH = 'products';

export const PRODUCTS_CONTENT_TYPE_BLOCK_V2 = 'product_v2';

// to-do: These values are coming from Storyblok https://api.storyblok.com/v2/cdn/datasource_entries?datasource=blockchain&token=8WLHr2NqBEM6JAJcgLP5aQtt
export const ETH_BLOCKCHAIN = {
  'name': 'Ethereum',
  'value': '1',
} as const;
export const POLYGON_BLOCKCHAIN = {
  'name': 'Polygon',
  'value': '137',
} as const;
