import React, { createContext } from 'react';

import { AnalyticsClient } from './client';

export interface AnalyticsRootContext {
  client: AnalyticsClient | undefined;
}

export interface AnalyticsRootProviderProps {
  context: AnalyticsRootContext;
}

export const AnalyticsRoot = createContext<AnalyticsRootContext>({
  client: void 0,
});

export const AnalyticsRootProvider: React.FC<
  React.PropsWithChildren<AnalyticsRootProviderProps>
> = (props) => {
  const { children, context } = props;

  return (
    <AnalyticsRoot.Provider value={context}>{children}</AnalyticsRoot.Provider>
  );
};
