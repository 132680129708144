type MixpanelImport = typeof import('mixpanel-browser');
type Mixpanel = MixpanelImport['default'];

let m: null | Mixpanel;

const load = async (): Promise<Mixpanel> => {
  if (!m) m = (await import('mixpanel-browser')).default;

  return m;
};

export const mixpanel = (fn: (mp: Mixpanel) => void) => {
  load().then(fn);
};
