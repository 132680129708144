import { useRouter } from 'next/router';
import Script from 'next/script';
import { useEffect } from 'react';

const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID;
const GTM_SERVER = process.env.NEXT_PUBLIC_GTM_SERVER;
const DATA_LAYER = 'dataLayerV2';

declare const window: Window & { gtm: (data: Record<string, unknown>) => void };

export const gtm = (properties: Record<string, unknown>) => {
  if (typeof window !== 'undefined' && window.gtm) {
    window.gtm(properties);
  }
};

export const gtmEvent = (
  event: string,
  properties: Record<string, unknown>,
) => {
  gtm({ event, ...properties });
};

export const GoogleTagManager = () => {
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (url: URL) => {
      gtm({ 'event': 'page_view', 'page_path': url });
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => router.events.off('routeChangeComplete', handleRouteChange);
  }, [router]);

  if (!GTM_ID || !GTM_SERVER) return null;

  return (
    <>
      <Script
        async
        src={`${GTM_SERVER}/gtm.js?id=${GTM_ID}&l=${DATA_LAYER}`}
        strategy="afterInteractive"
      />
      <script
        dangerouslySetInnerHTML={{
          __html: `
            window['${DATA_LAYER}'] = window['${DATA_LAYER}'] || [];
            function gtm(data) { window['${DATA_LAYER}'].push(data); };
            gtm({ event: 'gtm.js', 'gtm.start': new Date().getTime() });
          `,
        }}
      />
    </>
  );
};
