import { gtmEvent } from '../../../scripts/GoogleTagManager';
import { Provider } from './types';

export default {
  id: 'google',
  emit: (event) => {
    const name = [event.flow, event.object, event.name, event.action]
      .filter(Boolean)
      .join(' ');

    gtmEvent(name, event as unknown as Record<string, unknown>);
  },
} as Provider;
