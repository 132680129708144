import { isBrowser as isBrowserUtil } from '@utils/isBrowser';
import { useCallback } from 'react';

type UseSessionStorageReturnValue = {
  getSessionStorage: (key: string) => string;
  setSessionStorage: (key: string, value: string) => boolean;
  removeSessionStorageItem: (key: string) => void;
};

export const useSessionStorage = (): UseSessionStorageReturnValue => {
  const isBrowser = isBrowserUtil();

  const getSessionStorage = useCallback(
    (key: string) => {
      if (!isBrowser) return '';
      // iOS incognito throws
      try {
        return window.sessionStorage[key] as string;
      } catch (err) {
        console.error(err);
        return '';
      }
    },
    [isBrowser],
  );

  const setSessionStorage = useCallback(
    (key: string, value: string): boolean => {
      if (!isBrowser) return false;

      try {
        window.sessionStorage.setItem(key, value);
        return true;
      } catch (err) {
        console.error(err);
        return false;
      }
    },
    [isBrowser],
  );

  const removeSessionStorageItem = useCallback(
    (key: string): boolean => {
      if (!isBrowser) return false;

      try {
        window.sessionStorage.removeItem(key);
        return true;
      } catch (err) {
        console.error(err);
        return false;
      }
    },
    [isBrowser],
  );

  return {
    getSessionStorage,
    setSessionStorage,
    removeSessionStorageItem,
  };
};
