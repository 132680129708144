import {
  addBreadcrumb,
  captureException,
  captureMessage,
  SeverityLevel,
} from '@sentry/nextjs';
import { CaptureContext } from '@sentry/types';

type Breadcrumb = {
  level: SeverityLevel;
  message: string;
  category?: string;
};

export const captureBreadcrumb = (breadcrumb: Breadcrumb) => {
  addBreadcrumb({
    category: breadcrumb.category,
    message: breadcrumb.message,
    level: breadcrumb.level,
  });
};

export const captureError = (error: any, captureContext?: CaptureContext) => {
  captureException(error, captureContext);
};

export const captureErrorMessage = (message: string) => {
  captureMessage(message);
};
