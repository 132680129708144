import '../scripts/domainCheck';

import type { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import { appWithTranslation } from 'next-i18next';

import { useUTMTags } from '../hooks/useUTMTags';
import { createAnalyticsClient } from '../utils/analytics/client';
import { google, mixpanel, sentry } from '../utils/analytics/providers';
import { AnalyticsRootProvider } from '../utils/analytics/root';

const AppArteBio = dynamic(
  () => import('./_app_artebio').then((mod) => mod.AppArteBio),
  { ssr: true },
);

const AppDefault = dynamic(
  () => import('./_app_default').then((mod) => mod.AppDefault),
  { ssr: true },
);

const analyticsClient = createAnalyticsClient([google, mixpanel, sentry]);

const MyApp = (props: AppProps<{ isArteBioDomain?: boolean }>) => {
  useUTMTags();

  return (
    <AnalyticsRootProvider context={{ client: analyticsClient }}>
      {props.pageProps.isArteBioDomain ? ( // eslint-disable-line react/destructuring-assignment
        <AppArteBio {...props} />
      ) : (
        <AppDefault {...props} />
      )}
    </AnalyticsRootProvider>
  );
};

export default appWithTranslation(MyApp);
